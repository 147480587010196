import React, {useEffect,useState} from "react";
import "../styles/global.css"; // add some style if you want!
import "../styles/prism.css";
import { Helmet } from "react-helmet";
import BlockBadge from './BlockBadge'
export default function Index({ children }) {
    
  return (
    <div className="flex flex-col bg-gray-100 font-sans leading-normal tracking-normal min-h-screen">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Web3 Blog</title>
                <meta name="icon" href="ethicon.png" />
                <link rel="canonical" href="https://thewalkingcity.xyz" />
            </Helmet>
      <div className="w-full md:max-w-4xl mx-auto flex flex-wrap items-center justify-between mt-0 py-3">
        <nav id="header" className=" w-full z-10 top-0 border-b-2 border-gray-900">
          <div id="progress" className="h-1 z-20 top-0"></div>

          <div className="w-full md:max-w-4xl mx-auto flex flex-wrap items-center justify-between mt-0 py-3">
            <div className="pl-4">
              <a
                className="text-gray-900  no-underline hover:no-underline  text-xl"
                href="/"
              >
                blog.thewalkingcity.eth
              </a>
            </div>

            
            
            
            <div
              className="w-full flex-grow lg:flex justify-end lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-gray-100 md:bg-transparent z-20"
              id="nav-content"
            >
             
                  <a
                    className="inline-block py-2 px-4 text-gray-900 no-underline"
                   href="/about"
                  >
                    About
                  </a>
               
                  <a
                    className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-2 px-4"
                    href="/posts"
                  >
                    Posts
                  </a>
               
            </div>
          </div>
        </nav>
        {/* <div className="flex flex-col flex-grow items-end justify-end m-2"><BlockBadge></BlockBadge></div> */}
        
            {children}
            {/* {window && !window.location.host.includes("thewalkingcity.xyz") && <div className="text-sm"><div>Something wrong with this page?</div><a href={"https://www.thewalkingcity.xyz" + window.location.pathname}>Eject to web 1.0!</a></div>} */}
        </div>
      </div>
    
  );
}

export  {Index}